import axios from "axios";
import authHeader from "./auth-header";

const API_URL = "https://api.bzinweb.ru/web/v1/";

const getPublicContent = () => {
  return axios.get(API_URL + "default/", { headers: authHeader() });
};

const getUserBoard = () => {
  return axios.get(API_URL + "user", { headers: authHeader() });
};

const getModeratorBoard = () => {
  return axios.post(API_URL + "moderator/", {}, { headers: authHeader() });
};

const getAdminBoard = () => {
  return axios.get(API_URL + "admin", { headers: authHeader() });
};

export default {
  getPublicContent,
  getUserBoard,
  getModeratorBoard,
  getAdminBoard,
};