import axios from "axios";

const API_URL = "https://api.bzinweb.ru/web/v1/";

const register = (username, email, password) => {
    return axios.post(API_URL + "signup/", {
        username,
        email,
        password,
    });
};

const login = (username, password) => {
    return axios
        .post(API_URL + "signin/", {
            username,
            password,
        })
        .then((response) => {
            console.log("USER: " + response.data.accessToken);
            if (response.data.data.accessToken) {
                localStorage.setItem("user", JSON.stringify(response.data.data));
            }

            return response.data.data;
        });
};

const logout = () => {
    localStorage.removeItem("user");
};

export default {
    register,
    login,
    logout,
};